import React from 'react';
import { Link, graphql } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import Img from 'gatsby-image';

import Layout from '../../components/en';
import SEO from '../../components/seo';

import FeaturesMD from '../../components/features/features-fr';
import TechMD from '../../components/tech/tech-fr';
import AwardsMD from '../../components/awards/awards-fr';

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/content/products/**/*.fr.md" } }
      sort: { fields: [frontmatter___order], order: ASC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          id
          fields {
            slug
          }
          frontmatter {
            title
            available
            discount
            path
            role
            price
            avatar {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

class Products extends React.Component {
  render() {
    const { data } = this.props;
    const products = data.allMarkdownRemark.edges;
    // const { currentPage, numPages } = this.props.pageContext;
    // const isFirst = currentPage === 1;
    // const isLast = currentPage === numPages;
    // const prevPage =
    //  currentPage - 1 === 1
    //    ? '/fr/products/'
    //    : `/fr/products/${(currentPage - 1).toString()}`;
    // const nextPage = `/fr/products/${(currentPage + 1).toString()}`;

    return (
      <Layout data={data} location={typeof window !== 'undefined' ? location : null}>
        <SEO
          title="Nos boards - Furlan Snowboards"
          description="Nos supers boards pour la powpow et beaucoup de fun"
          pathname="/fr/products/"
          keywords={['snowboard', 'boards', 'versatility']}
        />
        <div className="productsPage">
          <div className="container mb-5">
            <div className="row">
              {products.map(({ node }) => (
                <div
                  className="col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-2"
                  key={node.id}
                >
                  <div className="product-card text-center">
                    <Link to={node.frontmatter.path}>
                      <Img
                        fluid={node.frontmatter.avatar.childImageSharp.fluid}
                        alt="Furlan Snowboards"
                      />
                      {/*
                      <Img
                        fluid={post.node.frontmatter.cover.childImageSharp.fluid}
                        alt="Furlan Snowboards"
                      />
                    */}
                    </Link>
                    <Link to={node.frontmatter.path}>
                      <h4 className="mb-1">{node.frontmatter.title}</h4>
                    </Link>
                    <div className="mb-2 text-white">
                      <Link to={node.frontmatter.path}>
                        <small>{node.frontmatter.role}</small>
                      </Link>
                    </div>
                    <Link to={node.frontmatter.path}>
                      <span className="badge badge-pill badge-warning badge-lg mb-2">
                        Acheter
                      </span>
                      <span className="badge badge-pill badge-primary badge-lg mb-2">
                        {!node.frontmatter.price == '0'
                          ? `${node.frontmatter.price}€`
                          : 'Club offer'}
                      </span>
                    </Link>
                    {node.frontmatter.discount == 'yes' && (
                      <span className="badge badge-pill badge-warning badge-lg">
                        -40%
                      </span>
                    )}
                    {node.frontmatter.available == 'no' && (
                      <span className="badge badge-pill badge-warning badge-lg">
                        Epuisée
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/*
            <div className="container page-links">
              <nav aria-label="Products navigation" className="mx-auto">
                <ul className="pagination">
                  {!isFirst && (
                    <li className="page-item">
                      <Link to={prevPage} rel="prev" className="page-link">
                        ← <FormattedMessage id="previous" />
                      </Link>
                    </li>
                  )}
                  {Array.from({ length: numPages }, (_, i) => (
                    <li
                      key={`pagination-number${i + 1}`}
                      className="page-item"
                      style={{
                        margin: 0,
                      }}
                    >
                      <Link
                        to={`/en/products/${i === 0 ? '' : i + 1}`}
                        className="page-link"
                      >
                        {i + 1}
                      </Link>
                    </li>
                  ))}
                  {!isLast && !numPages == 0 && (
                    <li className="page-item">
                      <Link to={nextPage} rel="next" className="page-link">
                        <FormattedMessage id="next" /> →
                      </Link>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          */}
          <hr />
          <FeaturesMD />
          <hr />
          <AwardsMD />
        </div>
      </Layout>
    );
  }
}

export default Products;
